import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core'
import { isPlatformBrowser } from '@angular/common'
import { RouterService } from "../../router/router.service";

declare let gtag: Function

@Injectable({
  providedIn: 'root'
})
export class CookieConsentService {
  constructor(@Inject(PLATFORM_ID) private platformId: InjectionToken<Record<string, unknown>>,
              private routerService: RouterService
  ) {}

  setCookiesAllowed(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }

    try {
      localStorage.setItem('jb_pp', '1')
      localStorage.setItem('jb_pp_ga', '1')
    } catch (e) {
      // NOP
    }

    if (typeof window !== 'undefined') {
      gtag('consent', 'update', {
        ad_storage: 'denied',
        analytics_storage: 'granted'
      })
    }

    window['ga-disable-UA-49878150-2'] = false
  }

  setCookiesDisallowed(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }

    try {
      localStorage.clear()
      sessionStorage.clear()
    } catch (e) {
      // NOP
    }
    window['ga-disable-UA-49878150-2'] = true
  }

  checkCookiesAllowed(): boolean {
    if (!isPlatformBrowser(this.platformId)) {
      return false;
    }

    try {
      const isCookieAllowed = localStorage.getItem('jb_pp') === '1';
      window['ga-disable-UA-49878150-2'] = !isCookieAllowed;

      if (isCookieAllowed) {
        this.executeAfterConsent();
        return true;
      }
    } catch (e) {
      window['ga-disable-UA-49878150-2'] = true;
    }

    return false;
  }

  executeAfterConsent(): void {
    this.loadAnalyticsScript()
    this.routerService.trackPageview().then(() => {})
  }

  loadAnalyticsScript(): void {
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-49878150-2';
    script.async = true;
    script.defer = true;
    body.appendChild(script);

    gtag('consent', 'default', {
      ad_storage       : 'denied',
      analytics_storage: 'denied',
      wait_for_update  : 500,
      region           : ['DE']
    });
    gtag('js', new Date());
    gtag('config', 'UA-49878150-2', {'send_page_view': false, 'anonymize_ip': true});
  }
}
