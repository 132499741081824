<div data-test="secondary-navigation" id="secondaryNavigation" [class.slide-in]="showSecondaryNavigation" [class.slide-out]="!showSecondaryNavigation && userInteraction">
  <ul class="nav no-style navbar-nav mx-md-auto mt-4 mt-sm-0 d-flex justify-content-center">
    <li class="nav-item" (click)="onNavigationClick()"><a class="px-3 py-2 nav-link" [routerLink]="['/', (isRegionMv ? _homepageMv : _homepageBb)]">Startseite</a></li>
    <li class="nav-item">
      <a *ngIf="isRegionMv" data-test="switch-region-bb" (click)="changeState()" href="javascript:void(0)" class="px-3 py-2 nav-link ag">Zu Berlin-Brandenburg</a>
      <a *ngIf="!isRegionMv" (click)="changeState()" href="javascript:void(0)" class="px-3 py-2 nav-link ag">Zu Mecklenburg-Vorpommern</a>
    </li>
    <li (click)="onNavigationClick()" class="nav-item">
      <a data-test="navigation-link" class="nav-link px-3 py-2" [routerLink]="_aboutUs">Über uns</a>
    </li>
    <li (click)="onNavigationClick()" class="nav-item">
      <a class="nav-link px-3 py-2" [routerLink]="_contact">Kontakt</a>
    </li>
    <li (click)="onNavigationClick()" class="nav-item">
      <a class="nav-link px-3 py-2" [routerLink]="_conditions">AGB</a>
    </li>
    <li (click)="onNavigationClick()" class="nav-item"><a class="nav-link px-3 py-2" [routerLink]="_privacyPolicy">Datenschutz</a></li>
    <li (click)="onNavigationClick()" class="nav-item"><a class="nav-link px-3 py-2" [routerLink]="_imprint">Impressum</a></li>
    <li (click)="onNavigationClick()" class="nav-item mt-4 border-0">
      <a class="nav-link fb-link px-3 py-2" href="https://www.facebook.com/derjobbote/">Besuche uns auf Facebook</a>
    </li>
  </ul>
</div>
