import { Component, Inject, InjectionToken, OnInit, PLATFORM_ID } from '@angular/core'
import { _privacyPolicy } from 'src/app/router/route-data'
import { isPlatformBrowser } from '@angular/common'
import { CookieConsentService } from '../../shared/services/cookie-consent.service'

@Component({
  selector: 'app-cookie-bar',
  templateUrl: './cookie-bar.component.html',
  styleUrls: ['./cookie-bar.component.scss']
})
export class CookieBarComponent implements OnInit {
  showCookie = true
  showUpdateConsent = false
  _privacyPolicy = _privacyPolicy

  constructor(
    @Inject(PLATFORM_ID) private platformId:  InjectionToken<Record<string, unknown>>,
    private cookieConsentService: CookieConsentService
  ) {}

  ngOnInit(): void {
    this.checkCookie()
  }

  private checkCookie(): void {
    if (isPlatformBrowser(this.platformId) && typeof navigator === 'object' && 'doNotTrack' in navigator) {
      this.showCookie = navigator.doNotTrack !== '1'
      this.showUpdateConsent = !this.showCookie
      return
    }

    this.showCookie = !this.cookieConsentService.checkCookiesAllowed()
    this.showUpdateConsent = !this.showCookie
  }

  accept(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }

    this.cookieConsentService.setCookiesAllowed()
    this.cookieConsentService.executeAfterConsent()
    this.toggleUiElements()
  }

  decline(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }

    this.cookieConsentService.setCookiesDisallowed()
    this.toggleUiElements()
  }

  toggleUiElements(): void {
    this.showUpdateConsent = !this.showUpdateConsent
    this.showCookie = !this.showCookie
  }
}
