import { Inject, Injectable, InjectionToken, PLATFORM_ID } from "@angular/core";
import { Router } from '@angular/router'
import { BehaviorSubject, Observable, forkJoin, throwError, EMPTY, shareReplay, finalize, of } from "rxjs";
import {
  BookingType,
  BookingObject,
  Booking,
  BookingFormState,
  PostJob,
  CompanyBooking,
  UnknownBooking,
  PostContact,
  SpecialOffer,
  RadioBundleWithPrice, CompanyForm, UpdateCompanyMedia, Flatrate
} from "../data/types";
import { WordpressService } from './wordpress.service'
import { take, map, switchMap, catchError } from "rxjs/operators";
import { isPlatformBrowser } from '@angular/common'
import { StateSelectService } from './state-select.service'
import { _bookingOverview, _bundles, _jobCreate } from '../../router/route-data'

export enum OrderType {
  none,
  unknown,
  company
}

interface UnknownOrderSuccess {
  logoPosted: boolean
  companyPosted: boolean
  contactPosted: boolean
  headerImagePosted: boolean
  jobPosted: boolean
  userPosted: boolean
  flatratePosted: boolean
}

interface CompanyOrderSuccess {
  headerImagePosted: boolean
  jobPosted: boolean
  flatratePosted: boolean
  flatrateDecreasePosted: boolean
}

@Injectable({ providedIn: "root" })
export class BookingService {
  private superuser = { username: 'imageUploadUser', password: '@HUB!4bEf9B*O)k9JG38%rNx' }
  localStorageBookingFormStateKey = 'bookingFormState'

  private companyBookingSubject = new BehaviorSubject<CompanyBooking>(undefined)
  companyBooking$ = this.companyBookingSubject.asObservable()

  private unknownBookingSubject: BehaviorSubject<UnknownBooking> = new BehaviorSubject<UnknownBooking>(undefined)
  unknownBooking$: Observable<UnknownBooking> = this.unknownBookingSubject.asObservable()

  private orderTypeSubject = new BehaviorSubject<OrderType>(OrderType.none)
  orderType$ = this.orderTypeSubject.asObservable()

  private unknownOrderSuccessSubject: BehaviorSubject<UnknownOrderSuccess>
  unknownOrderSuccess$: Observable<UnknownOrderSuccess>
  private companyOrderSuccessSubject: BehaviorSubject<CompanyOrderSuccess>
  companyOrderSuccess$: Observable<CompanyOrderSuccess>
  private orderFailedSubject: BehaviorSubject<boolean>
  orderFailed$: Observable<boolean>

  // BookingFormState
  private bookingFormStateSubject: BehaviorSubject<BookingFormState>
  bookingForm$: Observable<BookingFormState>

  // BookingState
  private bookingStateSubject: BehaviorSubject<Booking>
  bookingState$: Observable<Booking>

  // Flatrates
  private flatrates$: Observable<Flatrate[]>;

  // Routes
  _jobCreate = `/${_bundles}/${_jobCreate}`
  _bookingOverview = `/${_bundles}/${_bookingOverview}`

  defaultUnknownOrderSuccess(): UnknownOrderSuccess {
    return {
      logoPosted: false,
      companyPosted: false,
      contactPosted: false,
      headerImagePosted: false,
      jobPosted: false,
      userPosted: false,
      flatratePosted: false
    }
  }

  defaultCompanyOrderSuccess(): CompanyOrderSuccess {
    return {
      headerImagePosted: false,
      jobPosted: false,
      flatratePosted: false,
      flatrateDecreasePosted: false
    }
  }

  constructor(
    private router: Router,
    private wordpressService: WordpressService,
    private stateSelectService: StateSelectService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Record<string, unknown>>
  ) {
    // Success
    this.unknownOrderSuccessSubject = new BehaviorSubject(this.defaultUnknownOrderSuccess())
    this.unknownOrderSuccess$ = this.unknownOrderSuccessSubject.asObservable()
    this.companyOrderSuccessSubject = new BehaviorSubject(this.defaultCompanyOrderSuccess())
    this.companyOrderSuccess$ = this.companyOrderSuccessSubject.asObservable()
    this.orderFailedSubject = new BehaviorSubject<boolean>(false)
    this.orderFailed$ = this.orderFailedSubject.asObservable()

    // Error handling
    this.orderFailed$.subscribe((orderFailed) => {
      if (!orderFailed) {
        return
      }

      console.error('___ BOOKING ERROR ___')

      let text = 'Es kam bei einer Buchung zu einem HTTP Fehler:\n\n'

      forkJoin([
        this.bookingState$.pipe(take(1)),
        this.orderType$.pipe(take(1)),
        this.companyBooking$.pipe(take(1)),
        this.unknownBooking$.pipe(take(1)),
        this.unknownOrderSuccess$.pipe(take(1)),
        this.companyOrderSuccess$.pipe(take(1))
      ]).subscribe(([booking, orderType, companyBooking, unknownBooking, unknownOrderSuccess, companyOrderSuccess]) => {
        text += 'orderType: ' + orderType + ' \n\n'
        text += 'bookingType: ' + booking.bookingType + '\n\n'

        if (companyBooking?.headerImageFile) {
          companyBooking.headerImageFile = undefined
        }

        if (companyBooking?.postJob && companyBooking.previewJob) {
          if (companyBooking.previewJob.localHeaderImage) {
            companyBooking.previewJob.localHeaderImage = undefined
          }
          if (companyBooking.previewJob.localLogo) {
            companyBooking.previewJob.localLogo = undefined
          }
        }

        if (unknownBooking?.userForm?.password) {
          unknownBooking.userForm.password = undefined
          unknownBooking.userForm.passwordRepeat = undefined
        }

        if (orderType === OrderType.company) {
          text += 'companyOrderSuccess:\n\n'
          text += JSON.stringify(companyOrderSuccess) + '\n\n'
          text += 'companyBooking object:\n\n'
          text += JSON.stringify(companyBooking) + '\n\n'
        }

        if (orderType === OrderType.unknown) {
          text += 'unknownOrderSuccess:\n\n'
          text += JSON.stringify(unknownOrderSuccess) + '\n\n'
          text += 'companyBooking:\n\n'
          text += JSON.stringify(companyBooking) + '\n\n'
          text += 'unknownBooking:\n\n'
          text += JSON.stringify(unknownBooking) + '\n\n'
        }

        this.wordpressService.failedUpload(text).subscribe()
      })
    })

    // BookingFormState
    const localStorageBookingFormState = this.getLocalStorageBookingFormState()
    const initialState = localStorageBookingFormState || {}
    this.bookingFormStateSubject = new BehaviorSubject<BookingFormState>(initialState)
    this.bookingForm$ = this.bookingFormStateSubject.asObservable()

    // Booking
    this.bookingStateSubject = new BehaviorSubject<Booking>(undefined)
    this.bookingState$ = this.bookingStateSubject.asObservable()
  }

  private isFlatratesLoading = false;
  private initializeFlatrates$(): Observable<Flatrate[]> {
    return this.wordpressService.getAllFlatrates().pipe(
      take(1),
      catchError(() => of([])), // Handle errors, return an empty array for simplicity
      finalize(() => (this.isFlatratesLoading = false)),
      shareReplay(1)
    );
  }

  getFlatrates(): Observable<Flatrate[]> {
    if (!this.flatrates$ && !this.isFlatratesLoading) {
      this.isFlatratesLoading = true;
      this.flatrates$ = this.initializeFlatrates$();
    }

    return this.flatrates$ || of([]); // Return an empty array if the request is still loading
  }

  navigate(bookingType: BookingType, bookingObject: BookingObject, params?: unknown): void {
    this.bookingStateSubject.next({ bookingType: bookingType, bookingObject: bookingObject })
    this.router.navigate([this._jobCreate], params || undefined)
  }

  // Local storage / caching
  //////////////////////////

  setBookingFormState(bookingFormState: BookingFormState): void {
    this.bookingFormStateSubject.next(bookingFormState)
    if (isPlatformBrowser(this.platformId) && localStorage.getItem('jb_pp') === '1') {
      try {
        localStorage.setItem(this.localStorageBookingFormStateKey, JSON.stringify(bookingFormState))
      } catch (e) {
        // NOP
      }
    }
  }

  getLocalStorageBookingFormState(): BookingFormState {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }

    let bookingFormState
    try {
      bookingFormState = JSON.parse(localStorage.getItem(this.localStorageBookingFormStateKey))
    } catch (e) {
      // NOP
    }

    return bookingFormState
  }

  unknownBooking(companyBooking: CompanyBooking, unknownBooking: UnknownBooking): void {
    this.companyBookingSubject.next(companyBooking)
    this.unknownBookingSubject.next(unknownBooking)
    this.orderTypeSubject.next(OrderType.unknown)
    this.unknownOrderSuccessSubject.next(this.defaultUnknownOrderSuccess())
    this.orderFailedSubject.next(false)
    this.router.navigate([this._bookingOverview])
  }

  companyBooking(companyBooking: CompanyBooking): void {
    this.companyBookingSubject.next(companyBooking)
    this.orderTypeSubject.next(OrderType.company)
    this.companyOrderSuccessSubject.next(this.defaultCompanyOrderSuccess())
    this.orderFailedSubject.next(false)
    this.router.navigate([this._bookingOverview])
  }

  postCompanyFlatrate(jobId: string, companyId: string, flatrateId: string): void {
    this.wordpressService.postCompanyFlatrate({ flatrateId: flatrateId, companyId: companyId, jobId: jobId }).subscribe(
      () => {
        this.unknownOrderSuccess$.pipe(take(1)).subscribe((x) => this.unknownOrderSuccessSubject.next({ ...x, flatratePosted: true }))
        this.companyOrderSuccess$.pipe(take(1)).subscribe((x) => this.companyOrderSuccessSubject.next({ ...x, flatratePosted: true }))
      },
      () => this.orderFailedSubject.next(true)
    )
  }

  postJob(job: PostJob): void {
    this.wordpressService.postJob(job).subscribe({
      next: (id) => {
        this.unknownOrderSuccess$.pipe(take(1)).subscribe((x) =>
          this.unknownOrderSuccessSubject.next({
            ...x,
            jobPosted: true
          })
        )
        // Flatrates
        this.bookingState$.pipe(take(1)).subscribe((booking) => {
          // company flatrate
          if (booking.bookingType === BookingType.flatrate) {
            this.postCompanyFlatrate(id, job.companyId, booking.bookingObject.id)
          } else if (
            booking.bookingType === BookingType.specialoffer &&
            (<SpecialOffer>booking.bookingObject).flatrate &&
            (<SpecialOffer>booking.bookingObject).type === 'isFlatrate'
          ) {
            this.postCompanyFlatrate(id, job.companyId, (<SpecialOffer>booking.bookingObject).flatrate.id)
          } else if (booking.bookingType === BookingType.radio && (<RadioBundleWithPrice>booking.bookingObject).flatrate) {
            this.postCompanyFlatrate(id, job.companyId, (<RadioBundleWithPrice>booking.bookingObject).flatrate.id)
          } else {
            this.unknownOrderSuccess$.pipe(take(1)).subscribe((x) =>
              this.unknownOrderSuccessSubject.next({
                ...x,
                flatratePosted: true
              })
            )
          }
        })
      },
      error: () => this.orderFailedSubject.next(true)
    })
  }

  uploadAndPostCompany(booking: UnknownBooking, superuserToken: string): Observable<any> {
    return this.uploadImageAndGetId(booking, superuserToken).pipe(
      switchMap((logoId: string) => this.postCompany(booking.companyForm, logoId)),
      catchError((error) => {
        this.orderFailedSubject.next(true)
        if (isPlatformBrowser(this.platformId)) {
          console.error(error)
        }
        return throwError(() => error)
      })
    )
  }

  private postCompany(companyForm: CompanyForm, logoId: string): Observable<string> {
    companyForm.logo = logoId
    return this.wordpressService.postCompany(companyForm)
  }

  private uploadImageAndGetId(booking: UnknownBooking, superuserToken: string): Observable<string> {
    return this.wordpressService.uploadImage(booking.companyForm.logoFile, superuserToken).pipe(map((wpImageRes) => wpImageRes.id))
  }

  private postUser(unknownBooking: UnknownBooking, companyId: string): void {
    this.wordpressService
      .registerUser(
        unknownBooking.userForm.email,
        unknownBooking.userForm.password,
        companyId,
        unknownBooking.companyContactForm.salutation,
        unknownBooking.companyContactForm.firstName,
        unknownBooking.companyContactForm.name
      )
      .subscribe({
        next: () => {
          this.unknownOrderSuccess$.pipe(take(1)).subscribe((x) =>
            this.unknownOrderSuccessSubject.next({
              ...x,
              userPosted: true
            })
          )
        },
        error: (error) => {
          this.orderFailedSubject.next(true)
          if (isPlatformBrowser(this.platformId)) {
            console.error(error)
          }
        }
      })
  }

  private postContact(unknownBooking: UnknownBooking, companyId: string): void {
    const contact: PostContact = {
      ...unknownBooking.companyContactForm,
      companyId: companyId,
      user: '',
      isContact: true
    }
    if (!unknownBooking.companyForm.invoiceAddress) {
      contact.isInvoice = true
    }
    this.wordpressService.postContact(contact).subscribe({
      next: () => {
        this.unknownOrderSuccess$.pipe(take(1)).subscribe((x) =>
          this.unknownOrderSuccessSubject.next({
            ...x,
            contactPosted: true
          })
        )
      },
      error: () => this.orderFailedSubject.next(true)
    })
  }

  executeUnknownOrder([unknownBooking, companyBooking, superuserToken]: [UnknownBooking, CompanyBooking, string]): void {
    // company
    const companyId$ = this.uploadAndPostCompany(unknownBooking, superuserToken)

    companyId$.subscribe({
      next: (companyId) => {
        // Posting company and logo was succesful
        this.unknownOrderSuccess$
          .pipe(take(1))
          .subscribe((x) => this.unknownOrderSuccessSubject.next({ ...x, companyPosted: true, logoPosted: true }))

        this.postUser(unknownBooking, companyId)
        this.postContact(unknownBooking, companyId)

        // job
        const postJob: PostJob = { ...companyBooking.postJob }
        postJob.companyId = companyId
        postJob.stateId = this.stateSelectService.getState()
        postJob.userEmail = unknownBooking.userForm.email

        // optional header image
        if (companyBooking.headerImageFile) {
          this.uploadHeaderImage(companyBooking.headerImageFile, superuserToken).pipe(
            map((headerImageId) => {
              postJob.headerImageId = headerImageId;
              this.handleHeaderImageUploadSuccess(postJob);
            }),
            catchError((error) => {
              if (isPlatformBrowser(this.platformId)) {
                console.error("Error during image upload:", error);
              }
              this.orderFailedSubject.next(true);
              return EMPTY;
            })
          )
            .subscribe();
        } else {
          // Posting header image implicit succesful
          this.handleHeaderImageUploadSuccess(postJob);
        }
      },
      error: () => this.orderFailedSubject.next(true)
    })
  }

  private uploadHeaderImage(headerImageFile: File, token: string): Observable<string> {
    return this.wordpressService.uploadImage(headerImageFile, token)
      .pipe(
        map((wpImageRes) => wpImageRes.id)
      );
  }


  private handleHeaderImageUploadSuccess(postJob: PostJob): void {
    this.unknownOrderSuccess$.pipe(take(1)).subscribe((x) =>
      this.unknownOrderSuccessSubject.next({
        ...x,
        headerImagePosted: true
      })
    )

    this.postJob(postJob)
  }

  updateCompanyMedia(companyId: string, headerImageId: string): void {
    const updateMedia: UpdateCompanyMedia = {
      id: companyId,
      mediumId: headerImageId
    }
    this.wordpressService.addCompanyMedia(updateMedia).subscribe()
  }

  unknownOrder(): void {
    forkJoin([
      this.unknownBooking$.pipe(take(1)),
      this.companyBooking$.pipe(take(1)),
      this.wordpressService.getSuperUserToken(this.superuser.username, this.superuser.password)
    ]).subscribe({
      next: this.executeUnknownOrder.bind(this),
      error: () => this.orderFailedSubject.next(true)
    })
  }

  companyOrder(): void {
    const postCompanyJob = (companyBooking: CompanyBooking) => {
      this.wordpressService.postJob(companyBooking.postJob).subscribe({
        next: (jobId) => {
          this.companyOrderSuccess$.pipe(take(1)).subscribe((o) =>
            this.companyOrderSuccessSubject.next({
              ...o,
              jobPosted: true
            })
          )

          this.bookingState$.pipe(take(1)).subscribe((booking) => {
            // post flatrate
            if (booking.bookingType === BookingType.flatrate) {
              this.postCompanyFlatrate(jobId, companyBooking.postJob.companyId, booking.bookingObject.id)
            } else if (
              booking.bookingType === BookingType.specialoffer &&
              (<SpecialOffer>booking.bookingObject).flatrate &&
              (<SpecialOffer>booking.bookingObject).type === 'isFlatrate'
            ) {
              this.postCompanyFlatrate(jobId, companyBooking.postJob.companyId, (<SpecialOffer>booking.bookingObject).flatrate.id)
            } else if (booking.bookingType === BookingType.radio && (<RadioBundleWithPrice>booking.bookingObject).flatrate) {
              this.postCompanyFlatrate(jobId, companyBooking.postJob.companyId, (<RadioBundleWithPrice>booking.bookingObject).flatrate.id)
            } else {
              this.companyOrderSuccess$.pipe(take(1)).subscribe((o) =>
                this.companyOrderSuccessSubject.next({
                  ...o,
                  flatratePosted: true
                })
              )
            }
            // handle company flatrate
            if (booking.bookingType === BookingType.companyflatrate) {
              this.wordpressService.decreaseCompanyFlatrateQuota(booking.bookingObject.id, companyBooking.postJob.companyId).subscribe({
                next: () => {
                  this.companyOrderSuccess$.pipe(take(1)).subscribe((o) =>
                    this.companyOrderSuccessSubject.next({
                      ...o,
                      flatrateDecreasePosted: true
                    })
                  )
                },
                error: () => this.orderFailedSubject.next(true)
              })
            } else {
              // flatrate decrease implicit true
              this.companyOrderSuccess$.pipe(take(1)).subscribe((o) =>
                this.companyOrderSuccessSubject.next({
                  ...o,
                  flatrateDecreasePosted: true
                })
              )
            }
          })
        },
        error: () => this.orderFailedSubject.next(true)
      })
    }

    this.companyBooking$.pipe(take(1)).subscribe((companyBooking) => {
      if (companyBooking.headerImageFile) {
        this.wordpressService
          .uploadImage(companyBooking.headerImageFile)
          .pipe(map((wpImageRes) => wpImageRes.id))
          .subscribe({
            next: (id) => {
              this.companyOrderSuccess$
                .pipe(take(1))
                .subscribe((o) => this.companyOrderSuccessSubject.next({ ...o, headerImagePosted: true }))
              companyBooking.postJob.headerImageId = id
              this.updateCompanyMedia(companyBooking.postJob.companyId, id)
              postCompanyJob(companyBooking)
            },
            error: () => this.orderFailedSubject.next(true)
          })
      } else {
        // header image implicit true
        this.companyOrderSuccess$.pipe(take(1)).subscribe((o) => this.companyOrderSuccessSubject.next({ ...o, headerImagePosted: true }))
        postCompanyJob(companyBooking)
      }
    })
  }

  order(): void {
    this.orderType$.pipe(take(1)).subscribe((orderType: OrderType) => {
      if (orderType === OrderType.company) {
        this.companyOrder()
      }
      if (orderType === OrderType.unknown) {
        this.unknownOrder()
      }
    })
  }

  resetOrderState(): void {
    this.setBookingFormState({})
  }
}
