<nav class="navbar header navbar-light fixed-top">
  <!-- LEFT -->
  <a [routerLink]="['/', (isStateMv ? _homepageMv : _homepageBb)]"  class="navbar-brand">
    <img width="150" height="50" alt="Logo Der Jobbote" [priority]="true" ngSrc="../../../assets/img/logo_jobbote.svg" />
  </a>

  <!-- RIGHT -->
  <!-- logged in -->
  <ng-container *ngIf="user; else loggedOut">
    <!-- HeaderState.showLogin -->
    <div class="d-flex flex-row" *ngIf="showLogin">
      <ng-container *ngTemplateOutlet="bookingTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="dashboardTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="secondaryNavigationTemplate"></ng-container>
    </div>
    <!-- HeaderState.showForCompanies -->
    <div class="d-flex flex-row" *ngIf="showForCompanies">
      <ng-container *ngTemplateOutlet="forCompaniesTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="secondaryNavigationTemplate"></ng-container>
    </div>
    <!-- HeaderState.company -->
    <div class="d-flex flex-row" *ngIf="company">
      <h1 class="user-name align-self-center mr-2 mb-1 hideOnMediumDevices">{{ user?.name }}</h1>
      <ng-container *ngTemplateOutlet="companyNavigationTemplate"></ng-container>
    </div>
    <!-- HeaderState.faq -->
    <div class="d-flex flex-row" *ngIf="faq">
      <ng-container *ngTemplateOutlet="infoForCompaniesTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="dashboardTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="secondaryNavigationTemplate"></ng-container>
    </div>
  </ng-container>

  <!-- logged out -->
  <ng-template #loggedOut>
    <div class="d-flex flex-row" *ngIf="showLogin">
      <ng-container *ngTemplateOutlet="bookingTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="loginTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="secondaryNavigationTemplate"></ng-container>
    </div>
    <!-- HeaderState.showForCompanies -->
    <div class="d-flex flex-row" *ngIf="showForCompanies">
      <ng-container *ngTemplateOutlet="forCompaniesTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="secondaryNavigationTemplate"></ng-container>
    </div>
    <!-- HeaderState.company -->
    <div class="d-flex flex-row" *ngIf="company">
      <ng-container *ngTemplateOutlet="forCompaniesTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="secondaryNavigationTemplate"></ng-container>
    </div>
    <!-- HeaderState.faq -->
    <div class="d-flex flex-row" *ngIf="faq">
      <ng-container *ngTemplateOutlet="infoForCompaniesTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="loginTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="secondaryNavigationTemplate"></ng-container>
    </div>
  </ng-template>

  <ng-template #companyNavigationTemplate>
    <button (click)="toggleCompanyNavigation()" class="navbar-toggler nav-toggler" type="button">
      <ng-lottie width="32" height="32" [options]="lottieOptions" (animationCreated)="animationCreated($event)"></ng-lottie>
    </button>
    <app-company-navigation [showCompanyNavigation]="showCompanyNavigation" (navigationEvent)="onNavigationEventCompany()"></app-company-navigation>
  </ng-template>

  <ng-template #secondaryNavigationTemplate>
    <button
      data-test="secondary-navigation-toggle"
      title="Navigation auf- und zuklappen"
      class="navbar-toggler nav-toggler ml-2"
      type="button"
      (click)="toggleSecondaryNavigation()"
    >
      <ng-lottie width="32" height="32" [options]="lottieOptions" (animationCreated)="animationCreated($event)"></ng-lottie>
    </button>
    <app-secondary-navigation [showSecondaryNavigation]="showSecondaryNavigation" (navigationEvent)="onNavigationEvent()"></app-secondary-navigation>
  </ng-template>

  <ng-template #loginTemplate>
    <a class="d-none d-sm-block btn btn-outline-secondary hideOnExtraSmallDevices" [routerLink]="_company">Kundenbereich</a>
  </ng-template>

  <ng-template #bookingTemplate>
    <a class="btn btn-outline-secondary mr-2 d-none d-sm-inline-block" [routerLink]="['/', _bundles]">Stellenanzeige aufgeben</a>
  </ng-template>

  <ng-template #forCompaniesTemplate>
    <a class="btn btn-outline-secondary hideOnExtraSmallDevices" style="align-self: center;" [routerLink]="_companyInfo"
      ><span class="hideOnSmallDevices">Für&nbsp;</span>Arbeitgeber</a
    >
  </ng-template>

  <ng-template #infoForCompaniesTemplate>
    <a class="btn btn-outline-secondary mr-2 d-none d-sm-inline-block" [routerLink]="_companyInfo">
      <span class="hideOnSmallDevices">Informationen für </span>Arbeitgeber</a
    >
  </ng-template>

  <ng-template #dashboardTemplate>
    <a class="align-self-center btn btn-outline-secondary" [routerLink]="_company">Kundenbereich</a>
  </ng-template>
</nav>
