import { Route } from '@angular/router'

export enum HeroState {
  none,
  companyBanner,
  jobSearch, // TODO: remove
  regionSelect,
  jobSearchSmall,
  regionSelectBb
}

export enum HeaderState {
  company,
  showLogin,
  showForCompanies,
  faq,
  none
}

export interface AppRouteData {
  heroState: HeroState
  headerState: HeaderState
  regionLabel?: string
  state?: string
  seoData?: SEOData
  requiresNoStateSelection?: boolean
}

export interface SEOData {
  title: string
  path?: string
  tags?: string[]
  ogUrl?: string
  ogDescription?: string
  ogImage?: string
  ogImageWidth?: string
  ogImageHeight?: string
}

export interface AppRoute extends Route {
  data: AppRouteData
}

// Public
/////////

// Segments
export const _id = `:id`
export const _stateId = `:stateId`
export const _contacts = 'kontakte'
export const _edit = 'bearbeiten'
export const _select = 'wechseln'
export const _job = 'stellenanzeige'

// Routes
/////////
export const _jobs = 'stellenanzeigen'
export const _jobs_id = `${_jobs}/${_id}`

export const _maintenance = 'wartung'
export const _contact = 'kontakt'
export const _aboutUs = 'ueber-uns'
export const _stateselect = 'bundesland'
export const _companyInfo = 'informationen-für-arbeitgeber'
export const _homepageMv = 'mecklenburg-vorpommern'
export const _homepageBb = 'berlin-brandenburg'
export const _bundles = 'pakete'
export const _bundles_id = `${_bundles}/${_id}`
export const _conditions = 'agb'
export const _privacyPolicy = 'datenschutz'
export const _imprint = 'impressum'
export const _jobCreate = 'stellenanzeige-anlegen'
export const _jobCreateCompany = 'stellenanzeige-anlegen/2'
export const _bookingOverview = 'stellenanzeige-anlegen/übersicht'

// Company routes
/////////////////
export const _company = `unternehmen`
export const _company_login = `login`
export const _company_register = `registrieren`
export const _forgotPassword = `passwort-vergessen`
export const _company_job = `${_company}/${_job}`
export const _media = `medien`

// Region routes
////////////////
const _jobsIn = `${_jobs}-in`
export const _jobsInRostock = `${_jobsIn}-rostock`
export const _jobsInLandkreisRostock = `${_jobsIn}-landkreis-rostock`
export const _jobsInSchwerin = `${_jobsIn}-schwerin`
export const _jobsInLudwigslustParchim = `${_jobsIn}-ludwigslust-parchim`
export const _jobsInMecklenburgischeSeenplatte = `${_jobsIn}-mecklenburgische-seenplatte`
export const _jobsNordwestmecklenburg = `${_jobsIn}-nordwestmecklenburg`
export const _jobsVorpommernGreifswald = `${_jobsIn}-vorpommern-greifswald`
export const _jobsVorpommernRuegen = `${_jobsIn}-vorpommern-ruegen`

export const _jobsInBerlin = `${_jobsIn}-berlin`
export const _jobsInBrandenburgHavelland = `${_jobsIn}-brandenburg-havelland`
export const _jobsInElbeElsterOberspreewaldLausitzSpreeNeisse = `${_jobsIn}-elbe-elster-oberspreewald-lausitz-spree-neisse`
export const _jobsInFrankfurtOderSpree = `${_jobsIn}-frankfurt-oder-spree`
export const _jobsInPotsdamPotsdamMittelmark = `${_jobsIn}-potsdam-potsdam-mittelmark`
export const _jobsInPrignitzOstprignitzRuppinOberhavel = `${_jobsIn}-prignitz-ostprignitz-ruppin-oberhavel`
export const _jobsInTeltowFlaemingDahmeSpreewald = `${_jobsIn}-teltow-flaeming-dahme-spreewald`
export const _jobsInUckermarkBarnimMaerkischOberland = `${_jobsIn}-uckermark-barnim-maerkisch-oderland`

// Care: those are hardcoded against Regionen Names in Wordpress Backend
export const region_routeLabelRouteMap = {
  _jobsInRostock: { label: 'Stadt Rostock', route: _jobsInRostock },
  _jobsInLandkreisRostock: { label: 'Landkreis Rostock', route: _jobsInLandkreisRostock },
  _jobsInSchwerin: { label: 'Schwerin', route: _jobsInSchwerin },
  _jobsInLudwigslustParchim: { label: 'Ludwigslust-Parchim', route: _jobsInLudwigslustParchim },
  _jobsInMecklenburgischeSeenplatte: { label: 'Mecklenburgische Seenplatte', route: _jobsInMecklenburgischeSeenplatte },
  _jobsNordwestmecklenburg: { label: 'Nordwestmecklenburg', route: _jobsNordwestmecklenburg },
  _jobsVorpommernGreifswald: { label: 'Vorpommern-Greifswald', route: _jobsVorpommernGreifswald },
  _jobsVorpommernRuegen: { label: 'Vorpommern-Rügen', route: _jobsVorpommernRuegen },
  _jobsInBerlin: { label: 'Berlin', route: _jobsInBerlin },
  _jobsInBrandenburgHavelland: { label: 'Brandenburg / Havelland', route: _jobsInBrandenburgHavelland },
  _jobsInElbeElsterOberspreewaldLausitzSpreeNeisse: {
    label: 'Elbe-Elster / Oberspreewald-Lausitz / Spree-Neiße',
    route: _jobsInElbeElsterOberspreewaldLausitzSpreeNeisse
  },
  _jobsInFrankfurtOderSpree: { label: 'Frankfurt / Oder-Spree', route: _jobsInFrankfurtOderSpree },
  _jobsInPotsdamPotsdamMittelmark: { label: 'Potsdam / Potsdam-Mittelmark', route: _jobsInPotsdamPotsdamMittelmark },
  _jobsInPrignitzOstprignitzRuppinOberhavel: {
    label: 'Prignitz / Ostprignitz-Ruppin / Oberhavel',
    route: _jobsInPrignitzOstprignitzRuppinOberhavel
  },
  _jobsInTeltowFlaemingDahmeSpreewald: { label: 'Teltow-Fläming / Dahme-Spreewald', route: _jobsInTeltowFlaemingDahmeSpreewald },
  _jobsInUckermarkBarnimMaerkischOberland: {
    label: 'Uckermark / Barnim / Märkisch Oderland',
    route: _jobsInUckermarkBarnimMaerkischOberland
  }
}
